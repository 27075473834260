import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    this.element.addEventListener('change', this.submitLink);
  }

  submitLink (event) {
    // go to selected url
    window.location.replace(event.target.value);
  }
}
