// JS libraries
import "../scripts/frontend/setup_jquery.js";
import "../scripts/frontend/setup_translations.js";

import 'bootstrap';
import "popper.js/dist/umd/popper";

import Rails from 'rails-ujs';
Rails.start();

// Stimulus
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import NestedForm from 'stimulus-rails-nested-form'
import Reveal from 'stimulus-reveal-controller'

// jquery ui widgets
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/autocomplete";

// modules
import { Fancybox } from "@fancyapps/ui";

// Judge js and dependencies
import "../scripts/vendor/judge/judge.js";

window.Location = Location;

const application = Application.start();
const context = require.context('../scripts/controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

application.register('nested-form', NestedForm)
application.register('reveal', Reveal)
