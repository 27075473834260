import { Controller } from '@hotwired/stimulus';

// Controller that forwards clicks on table cell elements to the first link
// inside that table cell.
//
// This makes it possible to click anywhere in a table row to follow the link in
// each cell regardless of padding and margin - even if the link is empty.
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      const target = event.target;
      const nodeName = target.nodeName;
      if (nodeName == 'TD') {
        const element = event.currentTarget.querySelector('a');
        if (element !== undefined) {
          element.click();
        }
      }
    });
  }
}
