import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'audio',
    'pauseButton',
    'playButton',
    'progress',
    'progressBar'
  ]

  connect () {
    // Initially hide pause buttons and progress bars
    this.hideAll(this.pauseButtonTargets)
    this.hideAll(this.progressTargets)

    this.audioTargets.forEach(function(audioElement) {
      // Ensure the audio element is loaded before playback
      // Shouldn't make a difference, but there are reports of audio not playing on Firefox without it
      audioElement.load()

      // Setup progress bar for each audio element
      this.setupProgress(audioElement)
    }.bind(this))
  }

  hide (element) {
    element.style.display = 'none'
  }

  hideAll (elements) {
    elements.forEach(function(element) {
      element.style.display = 'none'
    })
  }

  play (event) {
    const index = event.params.index

    // Stop playing all other audio elements
    this.pauseAll()

    // Show play button for all audio elements
    this.hideAll(this.pauseButtonTargets)
    this.showAll(this.playButtonTargets)

    // Show pause button for this audio element
    this.hide(this.playButtonTargets[index])
    this.show(this.pauseButtonTargets[index])

    // Show only current progress bar when playing
    this.hideAll(this.progressTargets)
    this.show(this.progressTargets[index])

    this.audioTargets[index].play()
  }

  pause (event) {
    const index = event.params.index

    // Show play button for this audio element
    this.show(this.playButtonTargets[index])
    this.hide(this.pauseButtonTargets[index])

    this.audioTargets[index].pause()
  }

  pauseAll () {
    // stop all other audio elements
    this.audioTargets.forEach(function(audioElement) {
      audioElement.pause()
    })
  }

  setupProgress (audioElement) {
    const index = audioElement.dataset.index
    const progressBar = this.progressBarTargets[index]

    // Display progress
    audioElement.addEventListener('timeupdate', () => {
      progressBar.style.width = `${Math.floor(
        (audioElement.currentTime / audioElement.duration) * 100,
      )}%`;
    });
  }

  show (element) {
    element.style.display = 'block'
  }

  showAll (elements) {
    elements.forEach(function(element) {
      element.style.display = 'block'
    })
  }
}
