import Sortable from 'stimulus-sortable'
import Rails from 'rails-ujs'

export default class extends Sortable {
  static targets = ['photoCard']

  static values = {
    updateUrl: String
  }

  connect() {
    super.connect()
  }

  end() {
    const photoPositions = this.photoCardTargets.map((photoCard, index) => {
      let id = photoCard.dataset.photoId
      let position = index

      return {id: id, position: position}
    })

    this.persistPositions(photoPositions)
  }

  get defaultOptions() {
    return {
      animation: 500,
      handle: '.move-handle'
    }
  }

  persistPositions(positions) {
    const formData = new FormData()

    positions.forEach(function ({id, position}) {
      formData.append(`photos[${position}][position]`, position)
      formData.append(`photos[${position}][id]`, id)
    })

    Rails.ajax({
      data: formData,
      type: 'PATCH',
      url: this.updateUrlValue
    })
  }
}
