import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "submit",
  ]

  connect () {
    this.setupEventHandling()
  }

  setupEventHandling () {
    const form = this.element
    const submit = this.submitTarget

    form.addEventListener('ajax:beforeSend', (_event) => {
      submit.setAttribute('disabled', true);
    })

    form.addEventListener('ajax:success', (event) => {
      const xhr = event.detail[2]
      form.outerHTML = xhr.responseText;

      if (typeof(ga) != 'undefined') {
        ga(['send', 'pageview', '/reviews/create']);
      }
    })

    form.addEventListener('ajax:error', (event) => {
      const xhr = event.detail[2]
      form.outerHTML = xhr.responseText;
    })

    form.addEventListener('ajax:complete', (event) => {
      submit.removeAttribute('disabled');
    })
  }
}
