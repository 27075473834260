import cityPicker from '../backend/_city_picker';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output"]

  connect () {
    const input = this.inputTarget
    const output = this.outputTarget

    new cityPicker({
      input: input,
      output: output
    })

    this.disableEnterKey(input)
  }

  disableEnterKey (input) {
    input.addEventListener("keypress", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });
  }
}
