import { Controller } from '@hotwired/stimulus';
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  static values = {
    url: String
  }

  static targets = ['enable']

  show_iframe(event) {
    event.preventDefault()

    const formData = new FormData(this.element)
    const queryString = new URLSearchParams(formData).toString()

    const submitButtons = this.enableTargets
    var url = this.urlValue + '?' + queryString

    return Fancybox.show(
      [{
        preload: false,
        src: url,
        type: 'iframe',
        width: '576',
      }],
      {
        on: {
          destroy: function() {
            submitButtons.forEach((button) => {
              if (button.disabled) {
                button.disabled = false
              }
            })
          }
        }
      }
    )
  }
}
