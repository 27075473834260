import { Controller } from '@hotwired/stimulus'
import PhoneValidator from '../backend/phone_validator'

export default class extends Controller {
  static targets = [
    "input"
  ]

  connect () {
    new PhoneValidator(this.inputTarget, this.element)
  }
}
