import Sortable from 'stimulus-sortable'
import Rails from 'rails-ujs'

export default class extends Sortable {
  static targets = ['audioCard']

  static values = {
    updateUrl: String
  }

  connect() {
    console.log("AudioController connected")
    super.connect()
  }

  end() {
    const audioPositions = this.audioCardTargets.map((audioCard, index) => {
      let id = audioCard.dataset.audioId
      let position = index

      return {id: id, position: position}
    })

    this.persistPositions(audioPositions)
  }

  get defaultOptions() {
    return {
      animation: 500,
      handle: '.move-handle'
    }
  }

  persistPositions(positions) {
    const formData = new FormData()

    positions.forEach(function ({id, position}) {
      formData.append(`clips[${position}][position]`, position)
      formData.append(`clips[${position}][id]`, id)
    })

    Rails.ajax({
      data: formData,
      type: 'PATCH',
      url: this.updateUrlValue
    })
  }
}
