export default function PhoneValidator (selector, parent) {
  this.createInvalidFeedbackNode = function (errorMessage) {
    let invalidFeedbackNode = document.createElement("div");
    let invalidFeedbackContent = document.createTextNode(errorMessage);
    invalidFeedbackNode.appendChild(invalidFeedbackContent);
    invalidFeedbackNode.classList.add("invalid-feedback")

    return invalidFeedbackNode;
  }

  this.enableValidation = function (selector, parent, context) {
    selector.addEventListener("change", function (event) {
      let element = event.target;
      let phoneForm = element.form;
      let submit = phoneForm.querySelector("input[type='submit']");

      if (element.intlTelInput) {
        if (element.intlTelInput.isValidNumber()) {
          context.validPhoneNumber(element, submit, phoneForm);
        } else {
          context.invalidPhoneNumber(element, submit, parent, context);
        }
      }
    })
  }

  this.invalidPhoneNumber = function (element, submit, parent, context) {
    element.classList.add("is-invalid");
    let parentElement = parent;
    const errorMessage = element.getAttribute("data-error-message");
    let invalidFeedbackNode = context.createInvalidFeedbackNode(errorMessage);

    parentElement.appendChild(invalidFeedbackNode);

    submit.setAttribute("disabled", true);
  }

  this.validPhoneNumber = function (element, submit, phoneForm) {
    element.classList.remove("is-invalid")
    element.classList.add("is-valid")

    var toRemove = phoneForm.querySelector(".invalid-feedback")

    if (toRemove) {
      toRemove.parentNode.removeChild(toRemove);
    }

    submit.removeAttribute("disabled")
  }

  this.enableValidation(selector, parent, this);
}
