import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["toggler"];

  toggle() {
    this.togglerTargets.forEach(function(toggler) {
      toggler.classList.toggle("btn-primary");
    });
  }
}
