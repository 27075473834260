import Sortable from 'stimulus-sortable'
import Rails from 'rails-ujs'

// Expects the following:
//
// - A data-sort-items-target="position" on the item card. This should be an
//   input field that will be updated with the new position for the item when
//   ever a drag action ends.
//
// - An element with class="move-handle" that will be used as the drag handle.
export default class extends Sortable {
  static targets = ['itemCard']

  static values = {
    groupName: String,
    updateUrl: String
  }

  connect() {
    super.connect()
  }

  end() {
    const itemPositions = this.itemCardTargets
    .map((itemCard, index) => {
      let position = index
      let positionField = itemCard.querySelector('[data-sort-items-target="position"]')

      if (positionField) {
        positionField.value = position
      } else {
        let id = itemCard.dataset.itemId
        return {id: id, position: position}
      }
    })

    this.persistPositions(this.groupNameValue, itemPositions)
  }

  get defaultOptions() {
    return {
      animation: 500,
      handle: '.move-handle'
    }
  }

  itemCardTargetConnected(element) {
    this.recalculatePositions()
  }

  itemCardTargetDisconnected(element) {
    this.recalculatePositions()
  }

  persistPositions(itemName, positions) {
    if (!this.updateUrlValue) return

    const formData = new FormData()

    positions.forEach(function ({id, position}) {
      formData.append(`${itemName}[${position}][position]`, position)
      formData.append(`${itemName}[${position}][id]`, id)
    })

    Rails.ajax({
      url: this.updateUrlValue,
      type: 'PATCH',
      data: formData
    })
  }

  recalculatePositions() {
    this.itemCardTargets.forEach((itemCard, index) => {
      let position = index
      let positionField = itemCard.querySelector('[data-sort-items-target="position"]')
      if (positionField) {
        positionField.value = position
      }
    })
  }
}
