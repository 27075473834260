import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const element = this.element

    this.stateChangeForms().forEach(function(form) {
      form.addEventListener('ajax:success', (event) => {
        const xhr = event.detail[2]
        element.innerHTML = xhr.responseText;
      })
    })
  }

  stateChangeForms() {
    return this.element.querySelectorAll('form.button_to')
  }
}
