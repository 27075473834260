// global document

import ko from 'knockout';
import $ from 'jquery';

export default class ReplyViewModel {
  constructor() {
    this.section = ko.observable('');

    // Hide occupied sections submenus when entering the section
    this.section.subscribe(function hideSubSections(newSection) {
      if (newSection === 'occupied') {
        const targetId = 'reply-' + newSection;
        const target = document.getElementById(targetId);

        // Vanilla js version of collapsing the submenus
        target.querySelectorAll(':scope .collapse').forEach(function(element) {
          element.classList.remove('show');
        })
      }
    });
  }

  isSection(section) {
    return this.section() === section;
  }
}
